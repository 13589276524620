<template>
<section class="tables new-updated-design">
    <div class="row">
        <div class="col-lg-12 stretch-card">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ serverParams?.pro_sync ? $t('view-prosync-report') : $t('view-report') }}</h4>
                </div>
                <div class="card-body new-card-body">

                    <div v-html="info"></div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
    import Vue from "vue";
    import API from "@/api";
    export default {
        name: "ReportView",
        // props: ['serverParams'],
        data() {
            return {
                info: null,
            }
        },
        methods: {
            onCategoryChange() {
                this.$refs['report-table'].reset();
            },
            viewReportData() {
                this.serverParams = JSON.parse(this.$route.query.serverParams)
                if (!this.serverParams.category_id) {
                    return;
                }
                this.isLoading4 = true;
                API.viewReportData(
                    this.serverParams,
                    data => {
                        this.isLoading4 = false;
                        data.text().then(text => {
                            console.log('text,', text);
                            this.info = text;
                            this.isLoading4 = false;
                        });
                    },
                    err => {
                        this.isLoading4 = false
                    }
                );
            },
        },
        computed: {},
        created() {
        },
        mounted() {
            this.viewReportData();
            // this.info = this.$route.params.data;
            // var element = document.getElementById("MyDiv");
            // element.classList.remove("mystyle");
        }
    };
</script>

<style>
    @media (max-width: 1150px) {
        .card .new-card-body {
            padding: 1.25rem 1.437rem !important;
        }
    }
    .s {
        background-color: white !important;
        -webkit-animation: none !important;
        animation: none !important;
        font-weight: 400 !important;
    }
    .n {
        background-color: white !important;
    }
    table tr td {
        min-width: 130px !important;
        text-align: center !important;
        vertical-align: middle !important;
    }
    .container {
        overflow: scroll;
    }
    table tr {
        height: 25pt !important;
    }
    .new-card-body {
        overflow: scroll !important;
    }
    .new-card-body::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none; 
    }
    .navigation {
        display: none !important;
    }
</style>